<template>
	<div class="ui-header__text">
		<img class="ui-header__icon" src="@/assets/image/jobs/rectangle.png"/>
		{{ title }}
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				
			}
		},
		methods: {
			
		}
	}
</script>

<style scoped>
	.ui-header__text {
		display: flex;
		font-size: 24px;
		font-family: Noto Sans SC-Medium, Noto Sans SC;
		font-weight: 500;
		color: #333333;
		line-height: 28px;
		align-items: center;
	}
	.ui-header__icon {
		width: 15px;
		height: 23px;
		margin-right: 8px;
	}
</style>