import { send } from "../index";
// 盘点任务列表 财政
export function getSearchList(data, opts = {}) { // 财政管理
  return send({
    url: "/admin/inventoryTask/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 盘点调度列表 财政
export function getUnitTaskSearchList(data, opts = {}) { // 财政管理
  return send({
    url: "/admin/inventoryUnitTask/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 盘亏列表 财政
export function getlossListByUnit(data, opts = {}) { // 财政管理
  return send({
    url: "/admin/inventoryTask/lossListByUnit.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 盘盈管理列表 财政
export function getProfitList(data, opts = {}) { // 财政管理
  return send({
    url: "/admin/inventoryTask/profitList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 盘点结果列表	财政
export function getFiscalResultList(data, opts = {}) { 
  return send({
    url: "/admin/inventoryTask/fiscalResultList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 盘点结果列表
export function getInventoryResult(data, opts = {}) { 
  return send({
    url: "/admin/inventoryTask/inventoryResult.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 下达任务 财政
export function assignTask(data, opts = {}) { // 财政管理
  return send({
    url: "/admin/inventoryTask/assignTask.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 生成盘点结果
export function createInventoryResult(data, opts = {}) {
  return send({
    url: "/admin/inventoryTask/createInventoryResult.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 盘点结果确认 
export function postAffirm(data, opts = {}) {
  return send({
    url: "/admin/inventoryTask/affirm.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 提交盘点结果
export function submitInventoryResult(data, opts = {}) {
  return send({
    url: "/admin/inventoryTask/submitInventoryResult.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 盘点任务列表 
export function getSearchListByUnit(data, opts = {}) { 
  return send({
    url: "/admin/inventoryTask/searchListByUnit.do",
    method: "POST",
    data,
    ...opts,
  });
}

// 盘点任务列表  资产管理员
// export function getSearchListByUnit(data, opt ={}){ //资产管理
//   return send({
//     url:"/admin/inventoryTask/searchListByUnit.do",
//     method:"POST",
//     data,
//     ...opt
//   })
// }
// 盘点任务进度   资产管理员
export function getSearchListTask(data, opt={}){
  return send({
    url:"/admin/inventoryTaskDetails/searchList.do",
    method:"POST",
    data,
    ...opt
  })
}
// 盘亏管理  资产管理员
export function getLossList(data, opt ={}){
  return send({
    url:"/admin/inventoryTask/lossList.do",
    method:"POST",
    data,
    ...opt
  })
}
// 任务下达  资产
export function assignUnitTask(data, opt ={}){
  return send({
    url:"/admin/inventoryTask/assignUnitTask.do",
    method:"POST",
    data,
    ...opt
  })
}
// 下账申请  财政
export function applyKeepAccounts(data, opt ={}){
  return send({
    url:"/admin/assetsRecord/applyKeepAccounts.do",
    method:"POST",
    data,
    ...opt
  })
}
// 盘盈入库申请 
export function inventoryProfit(data, opt ={}){
  return send({
    url:"/admin/assetsRecord/inventoryProfit.do",
    method:"POST",
    data,
    ...opt
  })
}
// 盘盈入库申请 
export function lossOrProfitDetail(data, opt ={}){
  return send({
    url:"/admin/assetsRecord/detail.do",
    method:"POST",
    data,
    ...opt
  })
}
export function getAssetsDetail(data, opt ={}){
  return send({
    url:"/admin/assets/detail.do",
    method:"POST",
    data,
    ...opt
  })
}
// 下账报废盘盈 审核确认
export function postAudit(data, opt ={}){
  return send({
    url:"/admin/assetsRecord/audit.do",
    method:"POST",
    data,
    ...opt
  })
}
// 获得任务下达需要的介接收人
export function leaderListByUnitId(data, opt ={}){
  return send({
    url:"/admin/department/leaderListByUnitId.do",
    method:"POST",
    data,
    ...opt
  })
}